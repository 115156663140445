















































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'
import VClamp from 'vue-clamp'

import { CartModel } from '../../contracts'

import { shippingIcon } from '../Shippings/Shippings.helpers'
import { paymentIcon } from '../PaymentStep/PaymentStep.helpers'
import { Przelewy24ExcludedMethods } from '../PaymentStep/PaymentStep.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'CheckoutOverview', components: { VClamp } })
export class CheckoutOverview extends Vue {
  @Prop({ type: Object, required: true })
  public readonly cart!: CartModel

  @Prop({ type: Object, required: true })
  public readonly pages!: AnyObject

  @Prop({ type: Boolean, required: true })
  public readonly isLoading!: boolean

  @Prop({ type: Number, required: true })
  public readonly lastStep!: number

  @PropSync('agreements', { type: Array, required: true })
  public _agreements!: string[]

  public availableSteps: number = 5 // including overview
  protected expandedGroup: string[] = []
  protected readonly possibleAgreements: string[] = ['email-promotion', 'sms-promotion']

  public get checkAll (): boolean {
    return this.possibleAgreements.every((consent: string) => {
      return this._agreements.includes(consent)
    })
  }

  public set checkAll (checked: boolean) {
    this._agreements = checked ? [...this.possibleAgreements] : []
  }

  public get billingAddress () {
    return this.cart.billingAddress
  }

  public get shippingAddress () {
    return this.cart.shippingAddress
  }

  public get paymentMethod () {
    if (this.cart.selectedPaymentMethodId > 0 && Przelewy24ExcludedMethods.includes(this.cart.selectedPaymentMethodId)) {
      return {
        title: this.$t(`front.checkout.organisms.PaymentStep.Przelewy24.${this.cart.selectedPaymentMethodId}.title`),
        image: paymentIcon[this.cart.selectedPaymentMethodId]
      }
    }
    return {
      title: this.$t(`front.checkout.organisms.PaymentStep.method.${this.cart.selectedPaymentMethod?.code}.title`),
      image: paymentIcon[this.cart.selectedPaymentMethod!.code]
    }
  }

  public get shippingMethod () {
    return {
      price: this.cart.selectedShippingMethod?.price.value,
      title:
        this.$t(`front.checkout.organisms.ShippingStep.method.${this.cart.selectedShippingMethod?.methodCode}.title`),
      image: shippingIcon[this.cart.selectedShippingMethod!.methodCode]
    }
  }

  public isExpanded (key: string): boolean {
    return this.expandedGroup.includes(key)
  }

  public expand (key: string): void {
    if (this.isExpanded(key)) {
      this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1)
    } else {
      this.expandedGroup.push(key)
    }
  }

  /**
   * Fires step jump.
   */
  public jumpToStep (step: number): void {
    if (this.availableSteps > this.lastStep) {
      const diff = this.availableSteps - this.lastStep
      step = step - diff
    }
    this.$router.replace(
      { name: `${this.$route.name}`, query: { step: `${step}` } }
    )
  }

  /**
   * Handles placing order.
   */
  public async onOrder (): Promise<void> {
    this.$emit('onOrder')
  }
}
export default CheckoutOverview
