import { AnyObject } from '@movecloser/front-core'

import { BenefitsStep } from '../../organisms/BenefitsStep'
import {
  CustomerDetailsStep,
  CustomerDetailsStepSimplified
} from '../../organisms/CustomerDetailsStep'
import { DeliveryDetailsStep } from '../../organisms/DeliveryDetailsStep'
import { PaymentStepTwo } from '../../organisms/PaymentStepTwo'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const CIRCULAR_STEPPER_COMPONENT_KEY = 'CircularStepperComponent'

/**
 * TODO: Order is important!.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultComponentConfig: AnyObject = {
  steps: [
    {
      type: 'data',
      version: 'general'
    },
    {
      type: 'benefits',
      version: 'general'
    },
    {
      type: 'shipping',
      version: 'general'
    },
    {
      type: 'payments',
      version: 'general'
    }
  ]
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const stepsComponentConstructor: Record<string, AnyObject> = {
  data: {
    general: CustomerDetailsStep,
    simplified: CustomerDetailsStepSimplified
  },
  benefits: {
    general: BenefitsStep,
    simplified: BenefitsStep
  },
  shipping: {
    general: DeliveryDetailsStep,
    simplified: DeliveryDetailsStep
  },
  payments: {
    general: PaymentStepTwo,
    simplified: PaymentStepTwo
  }
}
