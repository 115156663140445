







































import { Component, Mixins, Watch, Inject as VueInject } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY, logger } from '../../../support'
import { CartItemData, OrderData, OrderResponse } from '../../../contexts'

import { FullscreenLoader } from '../../shared/molecules/Loader/FullscreenLoader.vue'
import { SidesLayout } from '../../shared/molecules/SidesLayout'

import { SingleCartItem } from '../organisms/SingleCartItem'
import { CartCoupons } from '../organisms/CartCoupons'
import { Step } from '../molecules/Step'
import { CircularStepper } from '../molecules/CircularStepper'
import { translateToCartItemEnhanced } from '../molecules/CartItem/CartItem.helpers'

import { CheckoutOverview } from '../organisms/CheckoutOverview'
import { CheckoutBase } from './base'
import { ORDER_KEY } from '../services/cart'
import CartSummary from '../organisms/CartSummary/CartSummary.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CheckoutTwo>({
  name: 'CheckoutTwo',
  components: {
    CartCoupons,
    CartSummary,
    FullscreenLoader,
    SidesLayout,
    SingleCartItem,
    Step,
    CircularStepper,
    CheckoutOverview
  },
  mounted () {
    this.pages = this.siteService?.getActiveSiteUrls()
    this.logo = this.siteService.getActiveSite().logo.light
    this.verifyIfStepSet()
    if (!this.cartLoading) {
      this.updateCheckoutFromCart()
    }
  }
})
export class CheckoutTwo extends Mixins(CheckoutBase) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  public logo: string = ''
  public shippingMethod: number | null = null

  public translateToCartItem = translateToCartItemEnhanced

  public lastStep: number = 5

  /**
   * FIXME
   */
  public onStep (payload: any): void {
    this.lastStep = payload.lastStep
    if (payload.step === 3) {
      this.shippingMethod = this.cart?.selectedShippingMethod?.price.value || 0
    }
  }

  public async placeOrder (): Promise<void> {
    if (!this.cart?.isSet()) {
      return
    }
    try {
      const getFirstVariant = (item: CartItemData) => {
        return Object.values(item.product.variants)[0]
      }
      const getFirstVariantKey = (item: CartItemData) => {
        const key = Object.keys(item.product.variants)[0] ?? ''
        return (key !== '_') ? key : ''
      }

      this.eventBus.emit('app:checkout.pre-purchase', {
        value: this.cart.total.value,
        items: this.cart?.items.map((item: CartItemData) => {
          const variant = getFirstVariant(item)
          const variantSlug = getFirstVariantKey(item)
          return {
            id: variant.sku,
            parent_id: item.product.sku,
            name: variant.name,
            variant: variantSlug,
            currency: this.cart?.total.currency,
            category: variant.attributes.mainCategory,
            brand: variant.attributes.brand,
            price: variant.price.finalPrice,
            quantity: item.quantity,
            url:
              this.siteService.getActiveSiteAddress() + `${item.product.urlPath}`,
            image: item.product.variants && (Array.isArray(variant.images) &&
              variant.images.length > 0)
              ? this.siteService.getActiveSiteAddress() +
              variant.images[0].url : ''
          }
        }),
        coupon: (this.cart?.coupons[0]) ? this.cart?.coupons[0].code : '',
        shipping: this.cart?.selectedShippingMethod?.price.value,
        tax: this.cart?.getTotalTax(),
        discount: (this.cart?.discounts || []).reduce((sum, discount) => {
          return sum + discount.amount.value
        }, 0),
        customer: this.cart?.email ? {
          id: this.cart?.email
        } : undefined
      })
    } catch (e) {
      logger(e, 'warn')
    }
    this.placingOrder = true

    try {
      const response = await this.checkoutService.placeOrder(this.cart.id)
      await this.afterOrderPlaced(response)
    } catch (e) {
      logger(e, 'warn')
      this.globalError = (e as Error).message
    }

    this.placingOrder = false
  }

  protected async saveOrderData (order: OrderResponse): Promise<void> {
    if (!this.cart || !this.cart.selectedShippingMethod) {
      throw new Error('Missing or invaild cart')
    }
    // ,
    //   cart: this.cart,
    //     deliveryTime: this.deliveryTime,
    //     applied_coupon_code: this.currentCouponCode ? this.currentCouponCode.code : null
    const orderData: OrderData = {
      ...order,
      cart: this.cart,
      deliveryFrom: '1',
      deliveryTo: '3',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      total: this.$options.filters!.currency(this.cart.getTotalPrice())
    }

    localStorage.setItem(ORDER_KEY, JSON.stringify(orderData))
  }

  @Watch('cart')
  protected initCheckout () {
    this.updateCheckoutFromCart()
  }

  protected verifyIfStepSet (): void {
    if (typeof this.$route.query.step !== 'string' || this.$route.query.step.length === 0) {
      this.moveToStep(1, true)
      return
    }

    const stepCandidate: number = parseInt(this.$route.query.step)
    if (stepCandidate < 1 || stepCandidate > this.lastStep) {
      this.moveToStep(1, true)
    }
  }
}

export default CheckoutTwo
